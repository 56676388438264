import React from 'react'
import { Hero as BulmaHero } from 'react-bulma-components'

const GRADIENT = 'linear-gradient(-45deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.15))'

export default function Hero({ backgroundImage, children, color, size, ...props }) {

  const style = backgroundImage
    ? {
        background: `${GRADIENT}, url(${backgroundImage}) no-repeat center center`,
        backgroundSize: 'cover',
      }
    : {}

  return (
    <BulmaHero
      color={color}
      size={size}
      style={style}
      {...props}
    >
      <BulmaHero.Body>{children}</BulmaHero.Body>
    </BulmaHero>
  )
}
