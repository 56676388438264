import React from "react"
import { navigate } from "gatsby-link"
import { Button, Form } from 'react-bulma-components'
const { Control, Field, Input, Textarea } = Form

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false, submitting: false };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault()
    this.setState({ submitting: true })
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => console.log(error))
  };

  render() {
    const { isTranslucent } = this.props
    const {
      emailAddress = '',
      message = '',
      name = '',
      submitting,
    } = this.state

    return (
        <form
          className={isTranslucent ? 'is-translucent' : ''}
          name="contact"
          method="post"
          action="/contact/thanks/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <div hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </div>
          <Field>
            <Control>
              <Input
                autoComplete="off"
                id="name"
                name="name"
                onChange={this.handleChange}
                placeholder="Full name"
                required
                type="text"
                value={name}
              />
            </Control>
          </Field>
          <Field>
            <Control>
              <Input
                autoComplete="off"
                id="emailAddress"
                name="emailAddress"
                onChange={this.handleChange}
                placeholder="E-mail address"
                required
                type="email"
                value={emailAddress}
              />
            </Control>
          </Field>
          <Field>
            <Control>
              <Textarea
                id="message"
                name="message"
                onChange={this.handleChange}
                placeholder="What can I help you with?"
                required
                type="textarea"
                value={message}
              />
            </Control>
          </Field>
          <Field>
            <Button
              color="info"
              fullwidth={true}
              loading={submitting}
              type="submit"
            >
              Send
            </Button>
          </Field>
        </form>
    )
  }
}