import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMapMarker, faPhone } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import {
  Columns,
  Container,
  Content,
  Heading,
  Section,
} from 'react-bulma-components'

import ContactForm from 'components/contact-form'

export default function Footer(props) {
  const { emailAddress, phoneNumber } = props

  return (
    <Section className="footer info has-text-dark" name="footer">
      <Container>
        <Columns>
          <Columns.Column className="padding-vertical-32" size={8}>
            <Heading className="has-text-dark" size={4}>Contact details</Heading>
            <Content>
              <p>
                <FontAwesomeIcon icon={faMapMarker} size="sm" style={{ marginRight: 10 }} />
                Based in Blackburn, UK
              </p>
              <p>
                <a className="has-text-dark" href={`tel:${phoneNumber}`}>
                  <FontAwesomeIcon icon={faPhone} size="sm" style={{ marginRight: 10 }} />
                  {phoneNumber}
                </a>
              </p>
              <p>
                <a className="has-text-dark"  href={`mailto:${emailAddress}?subject=Website enquiry`}>
                  <FontAwesomeIcon icon={faEnvelope} size="sm" style={{ marginRight: 10 }} />
                  {emailAddress}
                </a>
              </p>
            </Content>
          </Columns.Column>
          <Columns.Column className="padding-vertical-32" size={4}>
          <Heading size={4}>Send a message </Heading>
            <Content size="small">
              <ContactForm />
            </Content>
          </Columns.Column>
          <Columns.Column>
            <Content size="small" className="has-text-dark has-text-centered">
              <a
                className="has-text-dark"
                href="/privacy">Privacy Policy
              </a> | &copy; Jan Dignan Play Therapy 2019
            </Content>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}
