import React from 'react'
import Helmet from 'react-helmet'

import 'config/styles/base.sass'

export default ({ children }) => (
  <div>
    <Helmet>
      <html lang="en" />
      <title>Preview</title>
      <meta name="description" content="Preview" />

      <link rel="apple-touch-icon" sizes="180x180" href="/img/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/img/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/img/favicon-16x16.png" />
      <link rel="manifest" href="/img/site.webmanifest" />

      <link rel="mask-icon" href="/img/safari-pinned-tab.svg" color="#ff4400" />
      <meta name="theme-color" content="#fff" />
    </Helmet>

    {children}
  </div>
)