import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { Link } from 'gatsby'
import { Container, Navbar } from 'react-bulma-components'
import { compose, withState } from 'recompose'

const { Brand, Burger, Item, Menu } = Navbar

export default compose(
  withState('active', 'setActive'),
)(Navigation)

function Navigation({
  active,
  logo = {},
  pages = [],
  phoneNumber,
  setActive,
}) {
  // TODO Switch to hooks
  // const [active, setActive] = useState(false)

  const { alternativeText = '', image = '' } = logo
  const logoSrc = !!image.childImageSharp
    ? image.childImageSharp.fluid.src
    : image

  return (
    <Navbar active={active  || undefined} className="has-shadow">
      <Container>
        <Brand>
          {logoSrc && (
            <Item renderAs="a" href="/">
            <img
              alt={alternativeText}
              src={logoSrc}
              style={{ margin: '16px' }}
              width="200"
            />
          </Item>
          )}
          <Burger
            active={active || undefined}
            onClick={() => setActive(!active)}
          />
        </Brand>
        <Menu active={active}>
        <Navbar.Container position="start">
            {pages.map(({ label, path }, index) => (
              <Link
                key={index}
                className="navbar-item"
                to={path}
              >
                {label}
              </Link>
            ))}
          </Navbar.Container>
          <Navbar.Container position="end">
            <a className="navbar-item" href={`tel:${phoneNumber.replace(/ /g, '')}`}>
              <FontAwesomeIcon icon={faPhone} size="sm" style={{ marginRight: 10 }} />
              {phoneNumber}
            </a>
          </Navbar.Container>
        </Menu>
      </Container>
    </Navbar>
  )
}
